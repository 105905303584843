import React, { useState } from "react";
import "./style.css";
import ContactInfo from "./ContactInfo";

const Index = ({ title }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <div
        onClick={handleShowModal}
        className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer"
      >
        {title}
      </div>

      {showModal && (
        <div className="modal-overlay">
          <ContactInfo handleCloseModal={handleCloseModal} />
        </div>
      )}
    </div>
  );
};

export default Index;
