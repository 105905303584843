import React from "react";
import { FaHeart, FaHome, FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Cart from "./cart";
import { HiMiniSquares2X2 } from "react-icons/hi2";

const BottomNav = () => {
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white shadow-lg block md:hidden z-50">
      <nav className="flex justify-around items-center py-2">
        <Link
          to="/"
          className="flex flex-col items-center hover:text-gray-600 text-black"
        >
          <FaHome className="text-xl" />
          <span className="text-xs">Home</span>
        </Link>
        <Link
          to="/collection"
          className="flex flex-col items-center hover:text-gray-600 text-black"
        >
          <HiMiniSquares2X2 className="text-xl" size={27} />
          <span className="text-xs">Collection</span>
        </Link>
        <Link
          to="#"
          className="flex flex-col items-center hover:text-gray-600 text-black"
        >
          <Cart />
        </Link>
        <Link
          to="/wishlist"
          className="flex flex-col items-center hover:text-gray-600 text-black"
        >
          <FaHeart className="text-xl" />
          <span className="text-xs">Wishlist</span>
        </Link>
        <Link
          to="/profile"
          className="flex flex-col items-center hover:text-gray-600 text-black"
        >
          <FaUserAlt className="text-xl" />
          <span className="text-xs">Account</span>
        </Link>
      </nav>
    </div>
  );
};

export default BottomNav;
