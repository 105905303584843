import React from "react";
import "./style.css";

const PrivacyPolicy = ({ handleCloseModal }) => {
  return (
    <div className="modal">
      <article className="modal-container">
        <header className="modal-container-header">
          <span className="modal-container-title">
            <svg
              aria-hidden="true"
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14 9V4H5v16h6.056c.328.417.724.785 1.18 1.085l1.39.915H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8v1h-7zm-2 2h9v5.949c0 .99-.501 1.916-1.336 2.465L16.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 12 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z"
                fill="currentColor"
              />
            </svg>
            Terms and Services - Privacy Policy
          </span>
          <button className="icon-button" onClick={handleCloseModal}>
            <svg
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                fill="currentColor"
              />
            </svg>
          </button>
        </header>
        <section className="modal-container-body rtf">
          <span>
            At xolen.pk, we are committed to protecting your privacy and
            ensuring that your personal information is handled securely. This
            Privacy Policy explains how we collect, use, and protect your data
            when you visit or make purchases from our website.
          </span>
          <ol>
            <li>
              <strong>Information We Collect:</strong> We may collect the
              following information when you use our website:
              <ol>
                <li>
                  Personal Information: This includes your name, email address,
                  shipping address, phone number, and payment information when
                  you make a purchase or create an account.
                </li>
                <li>
                  Non-Personal Information: This includes data such as your IP
                  address, browser type, and browsing behavior on our website,
                  which we collect using cookies and similar technologies to
                  provide you with the best experience.
                </li>
              </ol>
            </li>
            <li>
              <strong>How We Use Your Information:</strong> We use the collected
              information for the following purposes:
              <ol>
                <li>
                  Order Processing: To process and deliver your orders,
                  including sending order confirmations and shipment updates.
                </li>
                <li>
                  Customer Service: To respond to your inquiries and provide
                  assistance.
                </li>
                <li>
                  Marketing: To send promotional emails and offers, which you
                  can opt out of at any time.
                </li>
                <li>
                  Website Improvement: To analyze user activity and improve our
                  website’s functionality and user experience.
                </li>
              </ol>
            </li>
            <li>
              <strong>How We Share Your Information:</strong> We do not sell or
              rent your personal information. However, we may share your data in
              the following cases:
              <ul>
                <li>
                  Service Providers: We may share your information with trusted
                  third-party providers including but not limited to payment
                  processors and delivery companies to fulfill your orders.
                </li>
                <li>
                  Legal Requirements: We may disclose your data if required by
                  law or in response to legal proceedings.
                </li>
                <li>
                  Business Transactions: In the event of a merger, sale, or
                  transfer of assets, your information may be transferred as
                  part of that transaction.
                </li>
              </ul>
            </li>
            <li>
              <strong>Data Security:</strong> We implement appropriate security
              measures to protect your personal information from unauthorized
              access, disclosure, or alteration. However, no online data
              transmission is completely secure, and we cannot guarantee
              absolute security.
            </li>
            <li>
              <strong>Your Rights:</strong> You have the right to:
              <ul>
                <li>
                  Access Your Information: Request a copy of the personal data
                  we hold about you.
                </li>
                <li>
                  Correct Your Information: Ask us to correct any inaccurate or
                  incomplete information.
                </li>
                <li>
                  Delete Your Information: Request the deletion of your personal
                  data, subject to certain legal obligations.
                </li>
              </ul>
            </li>
            <li>
              <strong>Third-Party Links:</strong> Our website may contain links
              to third-party websites. We are not responsible for the privacy
              practices of these sites and encourage you to review their privacy
              policies.
            </li>
            <li>
              <strong>Changes to This Policy:</strong> We reserve the right to
              update this Privacy Policy at any time. Any changes will be posted
              on this page with an updated date and will be effective
              immediately or else mentioned.
            </li>
            <li>
              <strong>Contact Us:</strong> If you have any questions or concerns
              about this Privacy Policy or how your data is handled, please
              contact us at:
              <ul>
                <li>Email: info@xolen.pk</li>
                <li>Phone: +92-0311-200-5795</li>
              </ul>
            </li>
          </ol>
          <br />
          <p>Last Updated: 19 Oct 2024</p>
          <br />
          <strong>Disclaimer:</strong>
          <p>
            Xolen reserves the right to amend or update this policy at any time.
            Any changes will be posted on this page with an updated date and
            will be effective immediately or else mentioned.
          </p>
        </section>
        <footer className="modal-container-footer">
          <button className="button is-ghost" onClick={handleCloseModal}>
            Decline
          </button>
          <button className="button is-primary" onClick={handleCloseModal}>
            Accept
          </button>
        </footer>
      </article>
    </div>
  );
};
export default PrivacyPolicy;
