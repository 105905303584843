import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/Banner";
import Search from "../components/filterProduct/Search";
import Pagination from "../components/paginationButtons";
import ProductList from "../components/ProductList";
import { getAllShoe } from "../statemanagement/slice/ShoeSlice";
// import { loginaUser } from "./../statemanagement/api/AuthenticationApi";

// Helper function to decode JWT
// const decodeJWT = (token) => {
//   if (!token) return null;
//   const base64Url = token.split(".")[1]; // Get the payload part of the token
//   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//   const jsonPayload = decodeURIComponent(
//     atob(base64)
//       .split("")
//       .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
//       .join("")
//   );
//   return JSON.parse(jsonPayload);
// };

const Home = ({ search = false }) => {
  const dispatch = useDispatch();

  const [Page, setPage] = useState(1);

  const { shoeData, loading, error } = useSelector(
    (state) => state.shoeDetails
  );
  const { limit, sort, brand, category, price, searchQuery } = useSelector(
    (state) => state.filterShoes
  );

  // Guest token provided
  const guestToken = process.env.REACT_APP_GUEST_TOKEN;

  // Automatically login guest if no token exists
  useEffect(() => {
    const token = localStorage.getItem("authenticate");
    const isguest = localStorage.getItem("isguest");
    if (!token || !isguest) {
      // No token found, logging in as guest
      localStorage.setItem("authenticate", guestToken);
      localStorage.setItem("isguest", true);

      // const decodedPayload = decodeJWT(guestToken);

      // Optionally dispatch a Redux action to store the guest login state
      // dispatch(loginaUser({ token: guestToken, user: decodedPayload }));
    }
  }, [dispatch, guestToken]);

  // Fetch products based on filters and current page
  useEffect(() => {
    dispatch(
      getAllShoe({
        page: Page,
        limit,
        sort,
        brand,
        category,
        price,
        searchQuery,
      })
    );
  }, [dispatch, Page, limit, sort, brand, category, price, searchQuery]);

  const style = {
    textAlign: "center",
    marginLeft: "10px",
  };

  return (
    <div className="min-h-[1400px]">
      {search && (
        <Search
          brandValue={brand}
          categoryValue={category}
          priceValue={price}
          pageValue={Page}
          loading={loading}
          searchOnly={true}
          show={true}
        />
      )}
      {!search && (
        <Search
          brandValue={brand}
          categoryValue={category}
          priceValue={price}
          pageValue={Page}
          loading={loading}
          searchOnly={true}
          show={false}
        />
      )}

      <Banner />

      {/* Product list */}
      <ProductList
        data={shoeData}
        loading={loading}
        error={error}
        category={category}
        title="Latest Collection"
        style={style}
        limit={8}
      />

      {/* Pagination Component */}
      <Pagination Page={Page} setPage={setPage} />
    </div>
  );
};

export default Home;
