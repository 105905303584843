import React from "react";

const ThankyouForShoppingOnlinePayment = () => {
return (
    <>
        <div className="flex h-screen items-center justify-center">
            <div>
                <div className="flex flex-col items-center space-y-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-28 w-28 text-green-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={1}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <h1 className="text-4xl font-bold text-center">Thank You For Shopping!</h1>
                    <input
                        className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                        type="file"
                        accept="image/*"
                        placeholder="Upload Screenshot"
                    />
                    <p className="text-center">
                        Please upload a screenshot of payment.
                    </p>
                    <button className="inline-flex items-center rounded border border-indigo-600 bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:outline-none focus:ring">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-3 w-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7 16l-4-4m0 0l4-4m-4 4h18"
                            />
                        </svg>
                        <span className="text-sm font-medium"> Home </span>
                    </button>
                </div>
            </div>
        </div>
    </>
);
};

export default ThankyouForShoppingOnlinePayment;
