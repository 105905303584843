import { FaRegHeart } from "react-icons/fa";

const wishListIcon = () => {
  return (
    <rect
      style={{
        width: "40px",
        height: "40px",
        backgroundColor: "#000",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FaRegHeart color="#fff" size="24" />
    </rect>
  );
};

export default wishListIcon;
