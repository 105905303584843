import React from "react";
// import icons
import { FaHeart, FaRegHeart, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addWishList, deleteWishList } from "../statemanagement/slice/WishList";
import { LoadingCircle, NotifyWarning } from "../toastify";
import { deleteShoe } from "../statemanagement/slice/ShoeSlice";
import UpdateProduct from "./Model/updateProduct";
import { decodeToken } from "react-jwt";

const Product = ({ Products }) => {
  const dispatch = useDispatch();
  const { wishListIDs, loading } = useSelector((state) => state.wishList);
  const token = localStorage.getItem("authenticate");
  const decodeData = decodeToken(token);

  let lastProductId = null;
  let confirmation = 0;

  const deleteShoeConfirm = ({ shoeId, product }) => {
    // If a new product is selected, reset the confirmation process
    if (lastProductId !== shoeId) {
      confirmation = 0;
      lastProductId = shoeId;
    }

    confirmation += 1;

    if (confirmation === 1) {
      NotifyWarning(`Click again to confirm deletion of ${product?.title}.`);
    } else if (confirmation === 2) {
      dispatch(deleteShoe({ shoeId, product: Products }));
      confirmation = 0;
      lastProductId = null;
    } else {
      confirmation = 0;
      lastProductId = null;
    }
  };

  const size = window.innerWidth > 768 ? "md" : "sm";

  return (
    <div className="shadow-0 px-1 pt-3 w-full max-w-xs mx-auto cursor-pointer hover:shadow-0 transition relative">
      <center>
        <div className="relative">
          <img
            className="mb-3 h-auto object-cover w-full transition-transform duration-300 ease-in-out transform hover:scale-105"
            src={Products.selectedFile[0]}
            alt={Products.title}
          />
        </div>
      </center>
      <div className="w-full h-full flex justify-center items-center opacity-100 transition duration-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ease-in-out hover:bg-[#00000003]">
        <Link to={`/product/${Products?._id}`}>
          <button className="absolute inset-0 flex items-center justify-center bg-transparent hover:bg-black hover:bg-opacity-20 opacity-0 hover:opacity-100 transition duration-300">
            {/* <BsEyeFill className="text-xl text-white" /> */}
          </button>
        </Link>

        {!token ? (
          <FaRegHeart
            className="text-2xl text-black transition duration-500 absolute top-5 left-5"
            title="Add to WishList"
            onClick={() =>
              NotifyWarning(
                "You must login or register to add items to your wishlist"
              )
            }
          />
        ) : wishListIDs?.find((item) => item === Products?._id) ? (
          loading ? (
            <LoadingCircle />
          ) : (
            <FaHeart
              className="text-2xl text-red-600 transition duration-500 absolute top-5 left-5"
              title="WishListed"
              onClick={() => dispatch(deleteWishList(Products?._id))}
            />
          )
        ) : loading ? (
          <LoadingCircle />
        ) : (
          <FaRegHeart
            className="text-2xl text-black transition duration-500 absolute top-5 left-5"
            title="Add to WishList"
            onClick={() =>
              dispatch(
                addWishList({ shoeId: Products?._id, product: Products })
              )
            }
          />
        )}

        {decodeData?.role === true && (
          <>
            {loading ? (
              <LoadingCircle />
            ) : (
              <>
                <button
                  className={`bg-black hover:bg-[#1f3f9c] ${
                    size === "md" ? "fixed right-0 -bottom-5 ml-7 mb-0" : ""
                  } z-50 rounded-full p-2 text-white text-2xl cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out`}
                  onClick={() => {
                    const shoeId = Products?._id;
                    deleteShoeConfirm({ shoeId, product: Products });
                  }}
                >
                  <FaTrashAlt
                    className="text-2xl"
                    title="Delete Shoe"
                  />
                </button>
                <UpdateProduct shoeId={Products?._id} product={Products} />
              </>
            )}
          </>
        )}
      </div>
      <div className="block lg:flex m-0 justify-items-center justify-between bg-white rounded-lg text-black font-small sm:font-medium md:text-xl">
        <div className="text-black font-bold md:flex md:flex-col md:justify-evenly md:font-normal">
          {Products?.title.split(" ").slice(0, 3).join(" ")}
        </div>
        <div className="flex justify-between md:block">
          <div className="text-gray-600">
            <s>Rs. {Products?.price}</s>
          </div>
          <div className="text-black">Rs. {Products?.discountedPrice}</div>
        </div>
      </div>
    </div>
  );
};

export default Product;
