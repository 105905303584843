import React from "react";
import ProductList from "../components/ProductList";
import { useDispatch, useSelector } from "react-redux";
import { getAllShoe } from "../statemanagement/slice/ShoeSlice";
import Banner from "../components/Banner";

function ProductPage() {
  const dispatch = useDispatch();
  const { loading, error, runningData, loungingData, everydayData } =
    useSelector((state) => state.shoeDetails);
  const { page, limit, sort, brand, category, price } = useSelector(
    (state) => state.filterShoes
  );
  React.useEffect(() => {
    dispatch(getAllShoe({ page, limit, sort, brand, category, price }));
  }, [dispatch, page, limit, sort, brand, category, price]);
  const style = {
    textAlign: "center",
    marginLeft: "10px",
  };
  return (
    <div className="min-h-[600px] mt-10">
      <Banner />
      <ProductList
        loading={loading}
        error={error}
        runningData={runningData}
        loungingData={loungingData}
        everydayData={everydayData}
        style={style}
        limit={12}
      />
    </div>
  );
}

export default ProductPage;
