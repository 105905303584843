import React from "react";
import "./style.css";
import ContactInfo from "../contactInfo";

const StoreLocation = ({ handleCloseModal }) => {
  return (
    <div className="modal">
      <article className="modal-container">
        <header className="modal-container-header">
          <span className="modal-container-title">
            <svg
              aria-hidden="true"
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14 9V4H5v16h6.056c.328.417.724.785 1.18 1.085l1.39.915H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8v1h-7zm-2 2h9v5.949c0 .99-.501 1.916-1.336 2.465L16.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 12 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z"
                fill="currentColor"
              />
            </svg>
            Contact Information
          </span>
          <button className="icon-button" onClick={handleCloseModal}>
            <svg
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                fill="currentColor"
              />
            </svg>
          </button>
        </header>
        <section className="modal-container-body rtf">
          <span>
            Currently we don't have any physical outlet and we function as an
            online store only. However, you can contact us via email and phone.
            Click below to know more:
          </span>
          <ul>
            <li>
              <ContactInfo />
            </li>
          </ul>
        </section>
        <footer className="modal-container-footer">
          <button className="button is-primary" onClick={handleCloseModal}>
            Close
          </button>
        </footer>
      </article>
    </div>
  );
};

export default StoreLocation;
