import React from "react";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import logo from "./../assets/favicon.ico";
import OrderPolicy from "./Model/orderPolicy/";
import PrivacyPolicy from "./Model/privacyPolicy/";
import ShippingPolicy from "./Model/shippingPolicy/";
import ContactInfo from "./Model/contactInfo/";
import StoreLocation from "./Model/storeLocation/";

const Footer = () => {
  return (
    <>
      <div className="mx-auto container py-12 xl:px-6 lg:px-6 sm:px-6 px-4">
        <hr className="p-2" />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="flex flex-col">
            <div className="font-medium text-yellow-400 text-lg">
              <img src={logo} alt="Logo" className="h-8" />
            </div>
            <p className="text-sm text-black mt-4">
              Copyright ©{new Date().getFullYear()} Xolen.pk.
            </p>
            <p className="text-sm text-black mt-4">All rights reserved</p>
            <div className="flex items-center gap-x-4 mt-6 text-white">
              <a
                href="https://www.facebook.com/share/5nRkqXzTAm9oVR5R/?mibextid=qi2Omg"
                target="_blank"
                rel="noreferrer"
                className="opacity-80 w-8 h-8 bg-[#00008B] cursor-pointer hover:bg-[#0000c6] rounded-full flex items-center justify-center"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/xolen.pk?igsh=YTBjbjduenY2NDZ4"
                target="_blank"
                rel="noreferrer"
                className="opacity-95 w-8 h-8 bg-[#e95950] cursor-pointer hover:bg-[#e95950] rounded-full flex items-center justify-center"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.tiktok.com/@xolen.pk?_t=8oAxVmdHg9N&_r=1"
                target="_blank"
                rel="noreferrer"
                className="opacity-80 w-8 h-8 bg-black cursor-pointer hover:bg-black rounded-full flex items-center justify-center"
              >
                <FaTiktok />
              </a>
            </div>
          </div>
          <div>
            <h2 className="text-base font-semibold text-black">Company</h2>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              Blog
            </p>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              About Us
            </p>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
            <ContactInfo title={"Contact Us"}/>
            </p>
          </div>
          <div>
            <h2 className="text-base font-semibold text-black">Support</h2>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              <OrderPolicy />
            </p>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              <ShippingPolicy />
            </p>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              <PrivacyPolicy />
            </p>
          </div>
          <div>
            <h2 className="text-base font-semibold text-black">Contact</h2>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              Xolen Pakistan
            </p>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              <StoreLocation />
            </p>
            <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
              <ContactInfo title={"Contact Info"}/>
            </p>
          </div>
        </div>
        <hr className="mt-4" />
        <div>
          <p className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer">
            Made with ❤️ by{" "}
            <a
              target="_blank"
              href="https://www.github.com/ammarsaa"
              rel="noreferrer"
            >
              AmmarSAA
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
