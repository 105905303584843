import React from "react";
import { NotifyInfo } from "../../../toastify";

export default function ShoeForOption({ shoeFor, setShoeFor, AddProductData }) {
  const reSetshoeFor = () => {
    setShoeFor({
      ...AddProductData,
      shoeFor: ["Men", "Women", "Kids", "Unisex"],
    });
  };
  return (
    <div className="flex flex-wrap items-center justify-center w-full">
      {shoeFor?.map((forOption, index) => (
        <div
          key={index}
          className="flex items-center bg-black rounded-full px-[0.6rem] py-1 mb-2 mr-2 text-sm text-white font-normal"
        >
          {forOption}
          <button
            type="button"
            className="ml-[0.3rem] flex-shrink-0 flex items-center justify-center h-4 w-4 rounded-full bg-white text-black leading-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-[#1f3f9c]"
            onClick={() => {
              setShoeFor({
                ...AddProductData,
                shoeFor: shoeFor.filter(
                  (t) => t !== forOption || shoeFor.length === 1
                ),
              });
              if (shoeFor.length === 1) {
                NotifyInfo("You must have at least one shoeFor option");
              }
            }}
          >
            <span className="sr-only">Remove shoeFor</span>
            <svg
              className="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l6 6M1 7l6-6"
              />
            </svg>
          </button>
        </div>
      ))}
      <button
        type="button"
        className=" bg-black rounded-full px-3 py-1 mb-2 mr-2 text-sm text-white font-normal"
        onClick={reSetshoeFor}
      >
        {" "}
        Reset{" "}
      </button>
    </div>
  );
}
