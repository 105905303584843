import API from "./index";

export const GetAllShoeAPI = ({
  page,
  limit,
  sort,
  brand,
  category,
  price,
  searchQuery,
}) => {
  if (searchQuery) {
    return API.get("/shoesPage/search", { params: { title: searchQuery } });
  }
  return API.get("/shoesPage", {
    params: {
      page,
      limit,
      sort,
      brand,
      category,
      price,
    },
  });
};

export const GetTopShoeAPI = () => API.get("/shoesPage/top");
export const GetShoeByIdAPI = (id) => API.get(`/shoesPage/${id}`);
export const CreateShoeAPI = (AddProductData) =>
  API.post("/shoesPage", AddProductData);
export const GetFilterData = () => API.get("/shoesPage/filter");
export const deleteShoeAPI = (shoeId) =>
  API.delete(`/shoesPage/delete/${shoeId}`);
export const updateShoeAPI = (shoeId, updateShoeData) =>
  API.put(`/shoesPage/${shoeId}`, updateShoeData);
export const GetShoeByTitleAPI = (title) =>
  API.get(`/shoesPage/search`, { params: { title } });
