import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { getCarts } from "./../../statemanagement/slice/cartSlice";
import BankInvoice from "./BankInvoice";
import { NotifyInfo } from "./../../toastify";

const Checkout = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { cartData, cartIds } = useSelector((state) => state.cart);

  // Close modal handler
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  // Fetch cart data when modal is open
  useEffect(() => {
    if (isOpen) {
      dispatch(getCarts());
    }
  }, [dispatch, isOpen]);

  // Map cart data to reflect user's added quantities
  const quantityUserHasAdded = cartIds.map((item) => item.quantity);
  const cartIdsOnly = cartIds.map((item) => item.cartId);

  const data = cartData.map((item) => {
    const index = cartIdsOnly.indexOf(item._id);
    return {
      ...item,
      quantityUserAdd: quantityUserHasAdded[index],
    };
  });

  const totalAmount = data?.reduce(
    (acc, item) => acc + item.quantityUserAdd * item.price,
    0
  );

  // Confirm Cash on Delivery
  let confirmCOD = 0;
  const confirmCashOnDelivery = () => {
    confirmCOD += 1;
    if (confirmCOD === 1) {
      NotifyInfo("Click again to confirm Cash on Delivery");
    } else if (confirmCOD === 2) {
      window.location.href = "/thankyou";
    } else {
      confirmCOD = 0;
    }
  }

  // if (!cartIds.length) {
  //   NotifyInfo("Your cart is empty");
  // }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-full py-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="py-16 px-4 md:px-6 2xl:px-0 flex justify-center items-center 2xl:mx-auto 2xl:container">
                    <div className="flex flex-col justify-start items-start w-full space-y-9">
                      <div className="flex justify-start flex-col items-start space-y-2">
                        <p className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
                          Checkout
                        </p>
                      </div>

                      {/* Cart Items Display */}
                      <div className="flex flex-wrap justify-center gap-6">
                        {data.map((item) => (
                          <div
                            key={item._id}
                            className="flex flex-col justify-center items-center bg-gray-100 py-7 px-10"
                          >
                            <div className="flex flex-col justify-start items-start w-full space-y-4">
                              <p className="text-xl md:text-2xl leading-normal text-gray-800">
                                {item.title}
                              </p>
                              <p className="text-base font-semibold leading-none text-gray-600">
                                {item.quantityUserAdd} x {item.price} = Rs.{" "}
                                {item.price * item.quantityUserAdd}
                              </p>
                            </div>
                            <div className="mt-6 w-52">
                              <img
                                src={item.selectedFile[0]}
                                alt={item.title}
                                className="rounded"
                              />
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Payment & Billing Section */}
                      <div className="flex flex-col lg:flex-row gap-8 bg-gray-100 p-8">
                        <div className="flex-1 space-y-6">
                          {/* Address Information */}
                          <input
                            className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                            type="text"
                            placeholder="Street Address"
                          />
                          <input
                            className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                            type="text"
                            placeholder="City"
                          />
                          <div className="flex space-x-4">
                            {/* <input
                              className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                              type="text"
                              placeholder="State"
                            /> */}
                            <select
                              className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                              placeholder="State"
                            >
                              <option value="">Select State</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Sindh">Sindh</option>
                              <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                              <option value="Balochistan">Balochistan</option>
                              <option value="Gilgit-Baltistan">Gilgit-Baltistan</option>
                              <option value="Gilgit-Baltistan">Kashmir</option>
                            </select>
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                              type="text"
                              placeholder="ZIP"
                            />
                          </div>
                          <input
                            className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                            type="text"
                            placeholder="Country"
                            defaultValue={"Pakistan"}
                          />

                          {/* Contact Information */}
                          <input
                            className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                            type="tel"
                            placeholder="Phone Number"
                          />
                          <input
                            className="border border-gray-300 p-4 rounded w-full text-base placeholder-gray-600 text-gray-600"
                            type="email"
                            placeholder="Email Address"
                          />

                          {/* Total and Checkout Button */}
                          <div className="flex items-center font-semibold">
                            <div className="mr-4">
                              Total = Rs. {totalAmount}
                            </div>
                          </div>
                        </div>

                        {/* Payment Methods */}
                        <div className="flex-1">
                          <div className="border p-4 rounded space-y-4">
                            <button
                              className="w-full bg-black text-white py-3 rounded hover:bg-blue-500"
                              onClick={confirmCashOnDelivery}
                            >
                              Cash On Delivery
                            </button>
                            <BankInvoice />
                            <button
                              className="w-full bg-black text-white py-3 rounded hover:bg-blue-500"
                              onClick={() => window.location.href = `/thankyou/OrderID=${Math.floor(Math.random() * 1000)}`}
                            >
                              Attach Screenshot
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Checkout;
