import React from "react";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import {
  getAllFilterData,
  setBrandValue,
  setCategoryValue,
  // setPriceValue,
  setSearchQuery,
} from "../../statemanagement/slice/filterShoes";
import { NotifySuccess, NotifyWarning } from "../../toastify";
import BrandDropdown from "./BrandDropdown";
import CategoryDropdown from "./Category";
// import PriceRangeDropdown from "./PriceRangeDropdown";

const Search = ({ brandValue, categoryValue, priceValue, pageValue, searchOnly, show }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAllFilterData());
  }, [dispatch]);

  const [Category, setCategory] = React.useState(
    categoryValue || "Collection (any)"
  );
  // const [Price, setPrice] = React.useState(priceValue || "Price range (any)");
  const [Brand, setBrand] = React.useState(brandValue || "Brand (any)");
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Dispatch search query if searchTerm is not empty
    if (searchTerm) {
      dispatch(setSearchQuery(searchTerm));
    } else {
      dispatch(setSearchQuery(""));
    }

    // Handling category, price, and brand filters
    if (Category !== "Collection (any)" && Category !== "All Collections") {
      dispatch(setCategoryValue(Category));
    } else {
      dispatch(setCategoryValue(""));
    }
    // if (Price !== "Price range (any)" && Price !== "All Prices") {
    //   dispatch(setPriceValue(Price));
    // } else {
    //   dispatch(setPriceValue(""));
    // }
    if (Brand !== "Brand (any)" && Brand !== "All Brands") {
      dispatch(setBrandValue(Brand));
    } else {
      dispatch(setBrandValue(""));
    }

    // Handle warnings
    if (
      Brand === "Brand (any)" &&
      // Price === "Price range (any)" &&
      Category === "Collection (any)" &&
      !searchTerm
    ) {
      return NotifyWarning("Please select any filter or enter a search term");
    }

    NotifySuccess("Filter applied successfully");
  };

  return (
    <>
      {show && (
        <div className="px-[30px] py-6 max-w-[1170px] mx-auto flex flex-col items-center lg:flex-row justify-between gap-4 lg:gap-x-3 relative -top-3 lg:-top-4 lg:shadow-1 bg-white lg:bg-white lg:backdrop-blur rounded-lg">
          {!searchOnly && (
            <>
              <BrandDropdown brand={Brand} setbrand={setBrand} />
              <CategoryDropdown Category={Category} setCategory={setCategory} />
            </>
          )}
          {/* <PriceRangeDropdown price={Price} setPrice={setPrice} /> */}
          <input
            type="text"
            placeholder="Search by title..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border rounded-lg px-4 py-2"
          />
          <button
            className="bg-black hover:bg-gray-500 transition w-full py-4 lg:max-w-[132px] rounded-lg flex justify-center items-center text-white text-lg"
            onClick={handleSubmit}
          >
            <RiSearch2Line />
          </button>
        </div>
      )}
    </>
  );
};

export default Search;
