import React from "react";
import "./style.css";

const ShippingPolicy = ({ handleCloseModal }) => {
  return (
    <div className="modal">
      <article className="modal-container">
        <header className="modal-container-header">
          <span className="modal-container-title">
            <svg
              aria-hidden="true"
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14 9V4H5v16h6.056c.328.417.724.785 1.18 1.085l1.39.915H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8v1h-7zm-2 2h9v5.949c0 .99-.501 1.916-1.336 2.465L16.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 12 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z"
                fill="currentColor"
              />
            </svg>
            Terms and Services - Shipping Policy
          </span>
          <button className="icon-button" onClick={handleCloseModal}>
            <svg
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                fill="currentColor"
              />
            </svg>
          </button>
        </header>
        <section className="modal-container-body rtf">
          <span>
            At Xolen, we ensure your shoes are delivered safely through our
            partner, Leopards Courier.
          </span>
          <ol>
            <li>
              <strong>Shipping Methods:</strong> We currently use Leopards
              Courier.
              <ul>
                <li>
                  <strong>Standard Shipping:</strong> 5-7 business days.
                </li>
                <li>
                  <strong>Scheduled Shipping (Not for COD Orders): </strong>
                  Parcel will be received after 5-7 business days from the
                  scheduled date.
                </li>
              </ul>
            </li>
            <li>
              <strong>Shipping Costs:</strong>
              <ul>
                <li>
                  Shipping costs are applied to the final billing amount
                  excluding discounts, promotions, sales, and taxes.
                </li>
                <li>
                  Free standard shipping on orders over PKR 5,000 within
                  Pakistan.
                </li>
                <li>
                  For orders below PKR 5,000, a flat rate of PKR 200 will be
                  charged.
                </li>
              </ul>
            </li>
            <li>
              <strong>Order Delivery Duration:</strong> Orders within Pakistan
              typically arrive within 5-7 business days for standard shipping.
            </li>
            <li>
              <strong>Order Processing Time:</strong> All orders are processed
              within 1-2 business days. Orders placed on weekends or holidays
              will be processed on the next business day.
            </li>
            <li>
              <strong>Shipping Restrictions:</strong> We do not ship to P.O.
              boxes or military addresses. Please provide a full physical
              address for delivery.
            </li>
            <li>
              <strong>Tracking Information:</strong> Once your order has been
              shipped, you will receive a tracking number via email or SMS.
            </li>
            <li>
              <strong>Returns and Exchanges:</strong> If you are not satisfied
              with your purchase, you can return or exchange your shoes. Refer
              to our Return & Refund Policy for details.
            </li>
            <li>
              <strong>Lost, Damaged, or Tampered Packages:</strong> If your
              package arrives damaged or tampered with, do not accept the
              delivery. Contact our customer service within 5 days at
              sales@xolen.pk or +92-311-200-5795.
            </li>
          </ol>
          <p>Last Updated: 19 Oct 2024</p>
        </section>
        <footer className="modal-container-footer">
          <button className="button is-ghost" onClick={handleCloseModal}>
            Decline
          </button>
          <button className="button is-primary" onClick={handleCloseModal}>
            Accept
          </button>
        </footer>
      </article>
    </div>
  );
};

export default ShippingPolicy;
