import React, { useState } from "react";
import "./style.css";
import PrivacyPolicy from "./PrivacyPolicy";

const Index = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <div
        onClick={handleShowModal}
        className="hover:text-blue-500 text-base mt-6 text-black cursor-pointer"
      >
        Privacy Policy
      </div>

      {showModal && (
        <div className="modal-overlay">
          <PrivacyPolicy handleCloseModal={handleCloseModal} />
        </div>
      )}
    </div>
  );
};

export default Index;
