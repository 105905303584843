import React from "react";

const ProductIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#000000" />
      <path d="M24 24L24 24L24 24Z" fill="white" />
      <path d="M24 24H16V16H24V24Z" fill="white" />
      <path d="M24 24H32V16H24V24Z" fill="white" />
      <path d="M24 24H16V32H24V24Z" fill="white" />
      <path d="M24 24H32V32H24V24Z" fill="white" />
    </svg>
  );
};

export default ProductIcon;
