import React from "react";
import "./style.css";

const OrderPolicy = ({ handleCloseModal }) => {
  return (
    <div className="modal">
      <article className="modal-container">
        <header className="modal-container-header">
          <span className="modal-container-title">
            <svg
              aria-hidden="true"
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14 9V4H5v16h6.056c.328.417.724.785 1.18 1.085l1.39.915H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8v1h-7zm-2 2h9v5.949c0 .99-.501 1.916-1.336 2.465L16.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 12 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z"
                fill="currentColor"
              />
            </svg>
            Terms and Services - Order Return/Exchange/Cancelation Policy
          </span>
          <button className="icon-button" onClick={handleCloseModal}>
            <svg
              height={24}
              width={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                fill="currentColor"
              />
            </svg>
          </button>
        </header>
        <section className="modal-container-body rtf">
          <span>
            At Xolen, we strive to provide the best shopping experience to our
            customers. If you are not satisfied with your purchase, we offer the
            following services:
          </span>
          <ol>
            <li>
              <strong>Return Policy:</strong> You may return your item(s) within
              5 working days of receiving your order, provided the following
              conditions are met:
              <ul>
                <li>
                  The item(s) must be unused and in original condition with all
                  tags and packaging intact.
                </li>
                <li>
                  Shoes should only be tried on clean surfaces to avoid any
                  signs of wear or damage.
                </li>
                <li>
                  A return request must be made within 5 working days of
                  receiving your order.
                </li>
              </ul>
            </li>
            <li>
              <strong>Return Steps:</strong> To initiate a return:
              <ol>
                <li>
                  Email us at <strong>sales@xolen.pk</strong> with the subject:
                  "Order Return - [Order ID]". Provide the reason for the return
                  and attach a video of the received parcel.
                </li>
                <li>
                  Alternatively, call <strong>+92-311-200-5795</strong> with
                  your order ID, reason for return, and video evidence of the
                  received parcel.
                </li>
                <li>
                  Upon approval, we will provide further instructions. You will
                  need to return the parcel via Leopards Courier, with shipping
                  charges of PKR 350 (excluding taxes).
                </li>
                <li>
                  Once the item is received and inspected, we will notify you of
                  the return status within 2 business days.
                </li>
              </ol>
            </li>
            <li>
              <strong>Return Charges:</strong>
              <ul>
                <li>
                  If the return is due to customer error (wrong size, change of
                  mind), the customer will bear the return shipping cost.
                </li>
                <li>
                  If Xolen made the mistake (wrong item/size shipped), Xolen
                  will cover the return shipping cost, and the cost will be
                  refunded upon return approval.
                </li>
              </ul>
            </li>
            <li>
              <strong>Non-Returnable Items:</strong> The following items are not
              eligible for return:
              <ul>
                <li>
                  Items that have been worn, damaged, or altered after delivery.
                </li>
                <li>Items not in their original packaging or without tags.</li>
                <li>Final sale or clearance items.</li>
              </ul>
            </li>
            <li>
              <strong>Refund Policy:</strong> Once your return is approved, you
              will be eligible for a refund:
              <ul>
                <li>
                  For payments via credit/debit card or bank transfer, the
                  refund will be processed within 7-10 business days to the
                  original payment method.
                </li>
                <li>
                  For Cash on Delivery (COD) payments, the refund will be issued
                  via bank transfer or mobile wallet, with details provided upon
                  return approval.
                </li>
              </ul>
            </li>
            <li>
              <strong>Important Notes:</strong>
              <ul>
                <li>
                  Shipping fees for the original delivery are non-refundable.
                </li>
                <li>
                  Return shipping fees are the customer's responsibility unless
                  otherwise stated.
                </li>
              </ul>
            </li>
            <strong>Order Cancellation Policy</strong>
            <p>
              At Xolen, we understand that sometimes plans change, and you may
              need to cancel your order. Our cancellation policy is designed to
              provide flexibility while ensuring timely order processing.
            </p>
            <ol>
              <li>
                <strong>How to Cancel an Order:</strong> If you wish to cancel
                your order, please contact our sales team as soon as possible
                via email or phone call:
                <ul>
                  <li>
                    Email: sales@xolen.pk, with subject: Order Cancelation -
                    [order id].
                  </li>
                  <li>
                    Call: +92-311-200-5795 (9AM to 5PM, Monday to Saturday,
                    GMT+05).
                  </li>
                </ul>
                Cancellations can only be processed if the request is made
                before the order has been shipped.
              </li>
              <li>
                <strong>Cancellation Window:</strong> Orders can be canceled
                within 24 hours of placing the order, provided the item has not
                yet been shipped.
                <br />
                If the cancellation request is made after the order has been
                shipped, we will be unable to cancel it, but you may initiate a
                return once you receive the parcel. For further information,
                please refer to our Return and Refund Policy.
              </li>
              <li>
                <strong>Refunds for Canceled Orders:</strong> If your order is
                successfully canceled, a refund will be issued:
                <ul>
                  <li>
                    For credit/debit card or bank transfer payments, the refund
                    will be processed to the original payment method within 5-7
                    business days.
                  </li>
                  <li>
                    For Cash on Delivery (COD) orders, no charges will apply as
                    payment is only collected upon delivery.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Special Conditions:</strong> If the order was placed
                during a sale or special promotion, canceled orders may not be
                eligible for a refund of any promotional or discount codes used.
              </li>
              <li>
                <strong>Failed Deliveries:</strong> If your order cannot be
                delivered due to an incorrect address or if the recipient is
                unavailable after multiple delivery attempts, the order will be
                canceled, and you may be responsible for return shipping fees.
              </li>
            </ol>
            <br />
            <p>Last Updated: 19 Oct 2024</p>
            <br />
            <strong>Disclaimer:</strong>
            <p>
              Xolen reserves the right to amend or update this policy at any
              time. Any changes will be posted on this page with an updated date
              and will be effective immediately or else mentioned.
            </p>
          </ol>
        </section>
        <footer className="modal-container-footer">
          <button className="button is-ghost" onClick={handleCloseModal}>
            Decline
          </button>
          <button className="button is-primary" onClick={handleCloseModal}>
            Accept
          </button>
        </footer>
      </article>
    </div>
  );
};

export default OrderPolicy;
