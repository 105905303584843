import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { GiRunningShoe } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { createShoe } from "../../statemanagement/slice/ShoeSlice";
import UploadImage from "../firebase/UploadImage";
import Category from "./productFunctions/category";
import ShoeForOption from "./productFunctions/shoeForOption";
import SizeOption from "./productFunctions/sizeOption.js";

export default function AddProduct() {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [AddProductData, setAddProductData] = React.useState({
    title: "",
    description: "",
    price: "",
    discountedPrice: "",
    category: ["Lounging", "Everyday", "Running"],
    shoeFor: ["Men", "Women", "Kids", "Unisex"],
    size: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
    quantity: "",
    selectedFile: [],
    brand: "",
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createShoe({ AddProductData, closeModal }));
  };

  const handleChange = (e) => {
    setAddProductData({ ...AddProductData, [e.target.name]: e.target.value });
  };

  const setSizes = (data) => {
    setAddProductData(data);
  };

  const size = window.innerWidth > 768 ? "md" : "sm";

  return (
    <>
      <button
        className={`bg-black hover:bg-[#1f3f9c] ${
          size === "md" && "fixed right-0 bottom-12 mr-7 mb-8"
        } z-50 rounded-full p-2 text-white text-2xl cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out`}
        onClick={openModal}
      >
        <p className="absolute text-white text-sm font-bold -mt-1 ml-4">+</p>
        <GiRunningShoe className="text-2xl" title="Add Product" />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mb-0 md:space-y-4 space-y-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium -m-2 text-black text-center"
                    >
                      Add New Shoe
                    </Dialog.Title>

                    <div>
                      <label htmlFor="title">Title</label>
                      <div className="mt-1">
                        <input
                          onChange={handleChange}
                          id="title"
                          name="title"
                          type="text"
                          autoComplete="title"
                          placeholder="Enter title"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="brand">Brand Name</label>
                      <div className="mt-1">
                        <input
                          onChange={handleChange}
                          id="brand"
                          name="brand"
                          type="text"
                          placeholder="Enter brand name"
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <label htmlFor="description">Description</label>
                      <div className="mt-1">
                        <textarea
                          onChange={handleChange}
                          id="description"
                          name="description"
                          type="text"
                          rows={1}
                          placeholder="Enter description"
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <UploadImage
                        AddProductData={AddProductData}
                        setAddProductData={setAddProductData}
                      />
                    </div>

                    <div className="w-full">
                      <label htmlFor="category">Collection</label>
                      <div className="mt-1">
                        <Category
                          category={AddProductData.category}
                          setCategory={setAddProductData}
                          AddProductData={AddProductData}
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <label htmlFor="shoeFor">Shoe For</label>
                      <div className="mt-1">
                        <ShoeForOption
                          shoeFor={AddProductData.shoeFor}
                          setShoeFor={setAddProductData}
                          AddProductData={AddProductData}
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <label htmlFor="size">Size</label>
                      <div className="mt-1">
                        <SizeOption
                          sizes={AddProductData.size}
                          setSizes={setSizes}
                          AddProductData={AddProductData}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-between space-y-4 md:space-y-0">
                      <div className="w-full md:w-1/4 md:pr-2">
                        <label
                          htmlFor="quantity"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Quantity
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={handleChange}
                            name="quantity"
                            type="number"
                            placeholder="Enter quantity"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="w-full md:w-1/4 md:pr-2">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Price
                        </label>
                        <div className="mt-1">
                          <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm bg-gray-200 rounded-l-md border border-r-0 border-gray-300">
                              Rs.
                            </span>
                            <input
                              type="text"
                              onChange={handleChange}
                              pattern="[0-9]*"
                              name="price"
                              className="flex-1 block w-full min-w-0 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Enter price"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-1/4 md:pr-2">
                        <label
                          htmlFor="discountedPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Discounted Price
                        </label>
                        <div className="mt-1">
                          <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm bg-gray-200 rounded-l-md border border-r-0 border-gray-300">
                              Rs.
                            </span>
                            <input
                              type="text"
                              onChange={handleChange}
                              pattern="[0-9]*"
                              name="discountedPrice"
                              className="flex-1 block w-full min-w-0 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Enter discounted price"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      id="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-[#1f3f9c] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1f3f9c]"
                    >
                      Add Shoe
                    </button>
                    <p className="text-center text-sm text-gray-500 first-letter:capitalize">
                      click on the overlay to close the popup window
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
